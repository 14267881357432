<template>
  <ExpandedCard title="Clients" :loading="loading_items">
    <template v-slot:header-right>
      <TableHeader class="mr-2" :has-add-button="false">
        <template v-slot:prepends>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                depressed
                outlined
                v-bind="attrs"
                v-on="on"
                class="text-none"
              >
                <span>{{ filter }}</span>
                <v-spacer />
                <v-icon right>mdi-menu-swap</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item
                v-for="(item, index) in filters"
                :key="index"
                @click="filter = item"
              >
                <v-list-item-title>{{ item }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </TableHeader>
    </template>
    <template v-slot:default>
      <v-row dense>
        <v-col :cols="mdAndUp ? 2 : 12">
          <v-sheet class="d-flex fullwidth" height="100">
            <div class="align-self-center mx-auto">
              <strong class="d-block fs-25 font-weight-bold primary--text">
                Today
              </strong>
              <h1 class="mt-2 primary--text fs-23 font-weight-bold text-center">
                {{ totalToday }}
              </h1>
            </div>
          </v-sheet>
          <v-sheet class="d-flex fullwidth" height="100">
            <div class="align-self-center mx-auto">
              <strong class="d-block fs-25 font-weight-bold primary--text">
                This Week
              </strong>
              <h1 class="mt-2 primary--text fs-23 font-weight-bold text-center">
                {{ totalWeek }}
              </h1>
            </div>
          </v-sheet>
          <v-sheet class="d-flex fullwidth" height="100">
            <div class="align-self-center mx-auto">
              <strong class="d-block fs-25 font-weight-bold primary--text">
                This Month
              </strong>
              <h1 class="mt-2 primary--text fs-23 font-weight-bold text-center">
                {{ totalMonth }}
              </h1>
            </div>
          </v-sheet>
          <v-sheet class="d-flex fullwidth" height="100">
            <div class="align-self-center mx-auto">
              <strong class="d-block fs-25 font-weight-bold primary--text">
                Total Clients
              </strong>
              <h1 class="mt-2 primary--text fs-23 font-weight-bold text-center">
                {{ stats.total_clients }}
              </h1>
            </div>
          </v-sheet>
          <v-sheet v-if="mdAndUp" class="d-flex fullwidth" height="200">
            <div class="align-self-center mx-auto">
              <v-avatar size="200" tile>
                <v-img :lazy-src="settings.loader" contain :src="image"></v-img>
              </v-avatar>
            </div>
          </v-sheet>
        </v-col>
        <v-col :cols="mdAndUp ? 10 : 12">
          <apexchart
            class="border-1 py-3 border-color--grey rounded-10 mb-1"
            height="170"
            type="area"
            :options="chartOptions"
            :series="series"
          ></apexchart>
          <VueTable
            v-if="view_type === 'list'"
            :items="items"
            :show-top-header="false"
            :headers="headers"
            :show-row-actions="false"
            icon="mdi-account-tie-outline"
            :loading="loading_items"
            empty-text="No client yet"
            title="Clients"
            :no-more-data="!has_more_items"
            :show-select="false"
            @load-more="loadMoreItems"
          >
            <template v-slot:row-slot="{ item }">
              <td class="clickable-td">
                <Avatar :user="item" :display="item.fullname" />
              </td>
              <td>
                {{
                  item.client_companies.length
                    ? item.client_companies[0].name
                    : item.company.name
                }}
              </td>
              <td>{{ item.email }}</td>
              <td>
                <v-chip
                  v-if="item.props && item.props.status === 'Active'"
                  small
                  pill
                  class="fullwidth"
                  color="primary"
                >
                  Active
                </v-chip>
                <v-chip v-else class="fullwidth" small pill color="error">
                  Inactive
                </v-chip>
              </td>
            </template>
          </VueTable>
          <!-- Grid View  -->
          <VueGrid
            v-else
            icon="mdi-account-tie-outline"
            empty-text="No client yet"
            title="Clients"
            show-affiliation
            :has-delete="false"
            :has-edit="false"
            :show-task-count="false"
            :items="items"
            @load-more="loadMoreItems"
            :no-more-data="!has_more_items"
          >
            <template slot="header-toolbar" class="icons">
              <v-progress-linear v-show="loading" :indeterminate="true" />
            </template>
          </VueGrid>
        </v-col>
      </v-row>
    </template>
  </ExpandedCard>
</template>

<script>
import ExpandedCard from '../components/ExpandedCard.vue'
import { fetch_services } from '@/services/fetch/fetch_services'
import request from '@/services/axios_instance'
import TableHeader from '@/common/TableHeader.vue'
import VueTable from '@/common/VueTable/VueTable.vue'
import VueGrid from '@/common/VueGrid/VueGrid.vue'
import { mapGetters } from 'vuex'
export default {
  mixins: [fetch_services],
  components: { ExpandedCard, TableHeader, VueTable, VueGrid },
  data: (vm) => ({
    image: require('@/assets/svgs/images/card-client.svg?internal').default,
    filter: 'All',
    filters: ['All', 'Active', 'Inactive'],
    stats: {
      today: {
        active: 0,
        inactive: 0
      },
      week: {
        active: 0,
        inactive: 0
      },
      month: {
        active: 0,
        inactive: 0
      },
      total_clients: 0
    },
    headers: [
      { text: 'Client', align: 'left', value: 'fullname' },
      { text: 'Affiliation', sortable: false, align: 'left' },
      { text: 'Email', align: 'left', value: 'email' },
      { text: 'Status', align: 'center', value: 'status' }
    ],
    series: [
      {
        name: 'Active',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      {
        name: 'Inactive',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      }
    ],
    chartOptions: {
      chart: {
        height: 350,
        type: 'area'
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth'
      },
      xaxis: {
        type: 'month',
        categories: vm.calendarMonths()
      }
    }
  }),
  computed: {
    ...mapGetters(['view_type']),
    today_series() {
      return [this.stats.today.active, this.stats.today.inactive]
    },
    totalToday() {
      return this.stats
        ? Number(this.stats.today.active) + Number(this.stats.today.inactive)
        : 0
    },
    totalWeek() {
      return this.stats
        ? Number(this.stats.week.active) + Number(this.stats.week.inactive)
        : 0
    },
    totalMonth() {
      return this.stats
        ? Number(this.stats.month.active) + Number(this.stats.month.inactive)
        : 0
    }
  },
  created() {
    this.getData()
    this.getClientMonthlyStatistics()
    this.loadItems(`api/clients`, true, null, null)
  },
  methods: {
    getData() {
      this.loading_items = true
      request
        .get(`api/dashboard-contents/clients`)
        .then(({ data }) => {
          this.stats = data
        })
        .finally(() => {
          this.loading_items = false
        })
    },
    getClientMonthlyStatistics() {
      this.loading_items = true
      request
        .get(`api/dashboard-contents/clients/monthly-statistics`)
        .then(({ data }) => {
          this.series = data
        })
        .finally(() => {
          this.loading_items = false
        })
    }
  }
}
</script>

<style lang="scss" scoped></style>
